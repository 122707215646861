import { medicalInstitutionsApi } from '@/src/api';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAsyncFetch } from '@/src/hooks/useAsyncFetch';
import { apiKey } from '@/src/hooks/useAsyncFetch/apiKey';
import { useFormWrapper } from '@/src/hooks/useFormWrapper';

import { FacilityTypeFilterModalPresenter, ModalInputMap } from './presenter';

type Props = Pick<
  React.ComponentProps<typeof FacilityTypeFilterModalPresenter>,
  'isShown' | 'onClose'
> & {
  selectedFacilityTypeIds: string[];
  onSubmit: (selectedFacilityTypeIds: string[]) => void;
};

export const FacilityTypeFilterModal: React.FC<Props> = (props) => {
  const facilityTypeGroupsResponse = useAsyncFetch(
    apiKey('medicalInstitutionsApi', 'getFacilityTypeGroups'),
    () => medicalInstitutionsApi.getFacilityTypeGroups(),
  );
  const throwFetchError = useFetchError();

  const form = useFormWrapper<ModalInputMap>({
    mode: 'all',
    defaultValues: {
      facilityTypeIds: props.selectedFacilityTypeIds,
    },
  });

  if (facilityTypeGroupsResponse.error) {
    throwFetchError(facilityTypeGroupsResponse.error);
  }

  const inputMap = form.watch();

  return facilityTypeGroupsResponse.data?.facilityTypeGroups ? (
    <FacilityTypeFilterModalPresenter
      isShown={props.isShown}
      onClose={props.onClose}
      facilityTypeGroups={facilityTypeGroupsResponse.data.facilityTypeGroups}
      form={{
        onSubmit: form.handleSubmit((data) => {
          props.onSubmit(data.facilityTypeIds);
        }),
        facilityTypeIds: {
          ...form.register('facilityTypeIds'),
          errorMessages: [],
          currentSelectedOptions: inputMap.facilityTypeIds,
          groupToggle: (isAllSelected, optionsKeys) =>
            form.setValue('facilityTypeIds', [
              ...(isAllSelected ? [] : optionsKeys),
              ...inputMap.facilityTypeIds.filter((selectedFacilityType) => {
                return !optionsKeys.includes(selectedFacilityType);
              }),
            ]),
        },
      }}
    />
  ) : null;
};
