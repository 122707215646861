import { getPagesToShow } from './logic/getPagesToShow';
import styles from './styles.module.scss';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import clsx from 'clsx';

type Props = {
  currentPage: number;
  lastPage: number;
  onClickPrevButton: VoidFunction;
  onClickNextButton: VoidFunction;
  onClickPageButton: (page: number) => void;
};

export const PaginationPresenter: React.FC<Props> = (props) => (
  <ul className={styles.base}>
    {props.currentPage !== 1 && (
      <li key="prev" className={clsx(styles.prevOrNext, styles.prev)}>
        <button
          onClick={props.onClickPrevButton}
          className={clsx(styles.button, styles.prevOrNextButton)}
        >
          <ChevronLeft />
          <span className={styles.text}>前</span>
        </button>
      </li>
    )}
    {getPagesToShow({
      currentPage: props.currentPage,
      lastPage: props.lastPage,
    }).map((page) => (
      <li
        key={page}
        className={clsx(
          styles.page,
          page === props.currentPage - 1 && styles.currentPagePrev,
          page === props.currentPage && styles.currentPage,
        )}
      >
        <button
          onClick={() => {
            props.onClickPageButton(page);
          }}
          className={styles.button}
        >
          <span className={styles.text}>{page}</span>
        </button>
      </li>
    ))}
    {props.currentPage !== props.lastPage && (
      <li key="next" className={clsx(styles.prevOrNext, styles.next)}>
        <button
          onClick={props.onClickNextButton}
          className={clsx(styles.button, styles.prevOrNextButton)}
        >
          <span className={styles.text}>次</span>
          <ChevronRight />
        </button>
      </li>
    )}
  </ul>
);
