import { MedicalInstitutionLabel } from '@/src/api/generated';
import { Input } from '@/src/components/foundations/Forms/Input';

import styles from './styles.module.scss';

import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type Props = {
  label: MedicalInstitutionLabel;
  form: {
    value: UseFormRegisterReturn;
  };
};

export const Text: React.FC<Props> = (props) => (
  <section className={styles.base}>
    <div>
      <h3 className={styles.labelName}>{props.label.name}</h3>
    </div>
    <div className={styles.inputWrapper}>
      <Input {...props.form.value} placeholder="キーワードを入力してください" />
    </div>
  </section>
);
