import { MedicalInstitutionLabelFilterConditionSchema } from '@/src/types/medicalInstitutionLabel';

export const isConditionEnabled = (
  condition: MedicalInstitutionLabelFilterConditionSchema,
): boolean => {
  return (
    (!!condition.textCondition?.value &&
      condition.textCondition.value.trim().length > 0) ||
    ((condition.integerCondition?.value ?? null) !== null &&
      !!condition.integerCondition?.conditionType) ||
    ((condition.selectionCondition?.ids ?? []).length > 0 &&
      !!condition.selectionCondition?.conditionType)
  );
};
