import { zodResolver } from '@hookform/resolvers/zod';
import {
  FieldArrayPath,
  FieldValues,
  useFieldArray as useRHFFieldArray,
  useForm as useRHF,
  UseFormReturn as RHFUseFormReturn,
  UseFormProps,
  FieldArrayWithId as RHFFieldArrayWithId,
  useFormState as useRHFFormState,
  UseFormStateProps,
} from 'react-hook-form';
import { z } from 'zod';

type Schema<S extends z.Schema> = z.infer<S>;

export const useForm = <S extends z.Schema>(
  schema: S,
  options?: UseFormProps<Schema<S>>,
) => {
  const form = useRHF<Schema<S>>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    ...options,
  });

  const errors = genErrors(form);

  return { form, errors };
};
export type UseFormReturn<T extends FieldValues> = RHFUseFormReturn<T>;

export const useFormState = <T extends FieldValues>(
  props: UseFormStateProps<T>,
) => {
  const formState = useRHFFormState(props);

  return { formState };
};

export const useFieldArray = <
  T extends FieldValues = FieldValues,
  P extends FieldArrayPath<T> = FieldArrayPath<T>,
  K extends string = 'id',
>({
  form,
  name,
  keyName,
}: {
  form: UseFormReturn<T>;
  name: P;
  keyName: K;
}) => {
  return useRHFFieldArray<T, P, K>({
    control: form.control,
    name,
    keyName,
  });
};

export type FieldArrayWithId<
  T extends FieldValues = FieldValues,
  P extends FieldArrayPath<T> = FieldArrayPath<T>,
  K extends string = 'id',
> = RHFFieldArrayWithId<T, P, K>;

export const genErrors = <T extends FieldValues>(form: UseFormReturn<T>) => {
  return (key: keyof T) => {
    const error = form.formState.errors[key];
    return error ? ([error.message].filter((x) => x) as string[]) : [];
  };
};
export type Errors<T extends FieldValues> = (key: keyof T) => string[];

export { z };
