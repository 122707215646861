import { Chip } from '@/src/components/foundations/Buttons/Chip';
import { useModal } from '@/src/hooks/useModal';

import { MedicalInstitutionLabelFilterModal } from '../../UtilModal/MedicalInstitutionLabelFilterModal';

type Props = {
  size: 'small' | 'medium';
} & Pick<
  React.ComponentProps<typeof MedicalInstitutionLabelFilterModal>,
  'onSubmit' | 'labelFilterConditions'
>;

export const MedicalInstitutionLabelFilterChip: React.FC<Props> = (props) => {
  const modal = useModal();
  const conditionSize = props.labelFilterConditions.length;

  return (
    <>
      <Chip
        size={props.size}
        status={conditionSize > 0 ? 'active' : 'normal'}
        onClick={modal.show}
      >
        医療機関ラベル
        {conditionSize > 0 && `：${conditionSize}`}
      </Chip>
      {modal.isShown && (
        <MedicalInstitutionLabelFilterModal
          isShown={modal.isShown}
          onClose={modal.hide}
          labelFilterConditions={props.labelFilterConditions}
          onSubmit={(labelFilterConditions) => {
            modal.hide();
            props.onSubmit(labelFilterConditions);
          }}
        />
      )}
    </>
  );
};
