import { z } from 'zod';

export const IntegerConditionType = z.enum(['moreEqual', 'lessEqual']);
export const SelectionConditionType = z.enum([
  'in',
  'notIn',
  'haveAny',
  'haveAll',
  'notHaveAll',
]);

export const medicalInstitutionLabelFilterConditionSchema = z.object({
  labelId: z.string(),
  textCondition: z
    .object({
      value: z.string(),
    })
    .optional(),
  integerCondition: z
    .object({
      value: z.number().nullish(),
      conditionType: IntegerConditionType,
    })
    .optional(),
  selectionCondition: z
    .object({
      ids: z.array(z.string()),
      conditionType: SelectionConditionType,
    })
    .optional(),
});

export type MedicalInstitutionLabelFilterConditionSchema = z.infer<
  typeof medicalInstitutionLabelFilterConditionSchema
>;
