import { useUpdateEffect } from '@/src/hooks/useUpdateEffect';

import { PaginationPresenter } from './presenter';

type Props = Pick<
  React.ComponentProps<typeof PaginationPresenter>,
  'currentPage' | 'lastPage'
> & {
  preventScrollToTop?: boolean;
  onClickCallback: (page: number) => void;
};

export const Pagination: React.FC<Props> = (props) => {
  const onClickPrevButton = () => {
    props.onClickCallback(Math.max(1, props.currentPage - 1));
  };
  const onClickNextButton = () => {
    props.onClickCallback(Math.min(props.currentPage + 1, props.lastPage));
  };
  const onClickPageButton = (page: number) => {
    props.onClickCallback(page);
  };

  useUpdateEffect(() => {
    // ページネーションのボタンを押したときに、トップにスクロールさせたくない場合はなにもしない
    if (props.preventScrollToTop) return;

    document.getElementById('scrollContainer')?.scrollTo(0, 0);
  }, [props.currentPage, props.preventScrollToTop]);

  return (
    <PaginationPresenter
      {...props}
      onClickPrevButton={onClickPrevButton}
      onClickNextButton={onClickNextButton}
      onClickPageButton={onClickPageButton}
    />
  );
};
