import { MedicalInstitutionLabel } from '@/src/api/generated';
import { CheckboxList } from '@/src/components/blocks/CheckboxList';
import { TextButton } from '@/src/components/foundations/Buttons/TextButton';
import { Label } from '@/src/components/foundations/DataDisplays/Label';
import { SelectBox } from '@/src/components/foundations/Forms/SelectBox';
import { Cluster } from '@/src/components/foundations/Layouts/Cluster';
import { Stack } from '@/src/components/foundations/Layouts/Stack';
import { SelectionConditionType } from '@/src/types/medicalInstitutionLabel';

import styles from './styles.module.scss';

import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type Props = {
  label: MedicalInstitutionLabel;
  form: {
    value: UseFormRegisterReturn;
    conditionType: UseFormRegisterReturn;
  };
  isAllSelected: boolean;
  groupToggle: VoidFunction;
};

export const Area: React.FC<Props> = (props) => {
  return (
    <Stack align="initial" gap={24}>
      <Stack align="initial" gap={16}>
        <Cluster justify="space-between" align="center">
          <h3 className={styles.labelName}>{props.label.name}</h3>
          <TextButton variant="primary" onClick={props.groupToggle}>
            {props.isAllSelected ? '全てを解除' : '全てを選択'}
          </TextButton>
        </Cluster>
        <CheckboxList
          {...props.form.value}
          options={
            props.label.areas?.map((s) => ({
              label: s.name,
              value: s.id.toString(),
            })) || []
          }
          gap={{ row: 16, column: 16 }}
        />
      </Stack>

      <Stack align="initial" width={343}>
        <Label
          text="絞り込み条件"
          position="top"
          distance={8}
          typography={{
            lineHeight: 'dense',
            fontSize: 14,
            fontWeight: 'normal',
          }}
        >
          <SelectBox
            {...props.form.conditionType}
            options={[
              {
                label: 'いずれかの項目を含む（OR検索）',
                value: SelectionConditionType.Values.in,
              },
              {
                label: 'すべての項目を含まない（NOT検索）',
                value: SelectionConditionType.Values.notIn,
              },
            ]}
          />
        </Label>
      </Stack>
    </Stack>
  );
};
