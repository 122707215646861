import { MedicalInstitutionLabel } from '@/src/api/generated';
import { NumberInput } from '@/src/components/foundations/Forms/NumberInput';
import { RadioGroup } from '@/src/components/foundations/Forms/RadioGroup';
import { IntegerConditionType } from '@/src/types/medicalInstitutionLabel';

import styles from './styles.module.scss';

import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type Props = {
  label: MedicalInstitutionLabel;
  form: {
    value: UseFormRegisterReturn;
    conditionType: UseFormRegisterReturn;
  };
};

export const Integer: React.FC<Props> = (props) => {
  return (
    <section className={styles.base}>
      <div>
        <h3 className={styles.labelName}>{props.label.name}</h3>
      </div>
      <div className={styles.inputWrapper}>
        <NumberInput {...props.form.value} />
      </div>
      <RadioGroup
        {...props.form.conditionType}
        radios={[
          {
            label: '以上',
            value: IntegerConditionType.Values.moreEqual,
          },
          {
            label: '以下',
            value: IntegerConditionType.Values.lessEqual,
          },
        ]}
        distance={16}
        orientation="horizontal"
      />
    </section>
  );
};
