import styles from './styles.module.scss';

import clsx from 'clsx';

type Size = 'small' | 'medium';
type Status = 'normal' | 'focus' | 'active';

type Props = {
  size: Size;
  status: Status;
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const Chip: React.FC<Props> = (props) => {
  const baseClassName = clsx(
    styles.base,
    styles[props.size],
    styles[props.status],
  );

  const children = <>{props.children}</>;

  return (
    <button
      type="button"
      className={clsx(baseClassName)}
      onClick={props.onClick}
    >
      {children}
    </button>
  );
};
