import { useResponsive } from './useResponsive';

import { useForm, UseFormProps, UseFormReturn } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type InputMap = Record<string, any>;

export const useFormWrapper = <T extends InputMap>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: UseFormProps<T, Record<string, any>> = {},
): UseFormReturn<T> => {
  const form = useForm<T>({
    reValidateMode: 'onBlur',
    criteriaMode: 'all',
    ...options,
  });
  const responsive = useResponsive();

  return {
    ...form,
    handleSubmit: (submitHandler, errorHandler) =>
      form.handleSubmit(
        (params, event) => {
          responsive.isSp &&
            ((document.activeElement as HTMLElement) || null)?.blur();
          submitHandler(params, event);
        },
        (error, event) => {
          responsive.isSp &&
            ((document.activeElement as HTMLElement) || null)?.blur();
          errorHandler && errorHandler(error, event);
        },
      ),
  };
};

/**
 * 出力をnumber | nullに変換するユーティリティ関数
 * valueAsNumberオプションが空文字の場合にNaNを返すのが不都合なので、nullを返すためのもの
 */
export const valueAsNumberOrNull = (
  value: string | number | null | undefined,
): number | null =>
  // 入力が空の場合、valueに空文字が入ってくることがあるので、?? ではなく || で判定していることに注意
  Number.isNaN(value || NaN) ? null : Number(value);
