import { MedicalInstitutionLabel } from '@/src/api/generated';
import { CheckboxList } from '@/src/components/blocks/CheckboxList';
import { Button } from '@/src/components/foundations/Buttons/Button';
import { Label } from '@/src/components/foundations/DataDisplays/Label';
import { SelectBox } from '@/src/components/foundations/Forms/SelectBox';
import { SelectionConditionType } from '@/src/types/medicalInstitutionLabel';

import styles from './styles.module.scss';

import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

type Props = {
  label: MedicalInstitutionLabel;
  form: {
    value: UseFormRegisterReturn;
    conditionType: UseFormRegisterReturn;
  };
  isAllSelected: boolean;
  groupToggle: VoidFunction;
};

export const SelectOrRadio: React.FC<Props> = (props) => (
  <section className={styles.base}>
    <div className={styles.wrapCheckbox}>
      <div className={styles.header}>
        <h3 className={styles.labelName}>{props.label.name}</h3>
        <Button
          color="primary"
          submitType="button"
          onClick={props.groupToggle}
          type="skeleton"
        >
          {props.isAllSelected ? '全てを解除' : '全てを選択'}
        </Button>
      </div>
      <CheckboxList
        {...props.form.value}
        options={
          props.label.selections?.map((s) => ({
            label: s.label,
            value: s.id.toString(),
          })) || []
        }
        gap={{ row: 16, column: 16 }}
      />
    </div>

    <div className={styles.inputWrapper}>
      <Label
        text="絞り込み条件"
        position="top"
        distance={8}
        typography={{
          lineHeight: 'dense',
          fontSize: 14,
          fontWeight: 'normal',
        }}
      >
        <SelectBox
          {...props.form.conditionType}
          options={[
            {
              label: 'いずれかの項目を含む（OR検索）',
              value: SelectionConditionType.Values.in,
            },
            {
              label: 'すべての項目を含まない（NOT検索）',
              value: SelectionConditionType.Values.notIn,
            },
          ]}
        />
      </Label>
    </div>
  </section>
);
