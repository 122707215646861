import {
  MedicalInstitutionLabel,
  MedicalInstitutionLabelComponentType,
} from '@/src/api/generated';
import { useForm } from '@/src/hooks/useForm';
import {
  IntegerConditionType,
  medicalInstitutionLabelFilterConditionSchema,
  SelectionConditionType,
} from '@/src/types/medicalInstitutionLabel';

import { z } from 'zod';

const schema = z.object({
  conditions: z.array(medicalInstitutionLabelFilterConditionSchema),
});

export type Schema = z.infer<typeof schema>;

export const useFiltersForm = (
  defaultValues: Schema['conditions'],
  labels: MedicalInstitutionLabel[],
) => {
  const { form } = useForm(schema, {
    mode: 'all',
    shouldUnregister: false,
    defaultValues: {
      conditions: labels.map(
        (label): NonNullable<Schema['conditions']>[number] => {
          const defaultValue = (defaultValues ?? []).find(
            (valueLabel) => valueLabel.labelId === label.id.toString(),
          );

          return defaultValue ?? initialValue(label);
        },
      ),
    },
  });

  return { form };
};

const initialValue = (
  label: MedicalInstitutionLabel,
): NonNullable<Schema['conditions']>[number] => {
  switch (label.componentType) {
    case MedicalInstitutionLabelComponentType.Text:
    case MedicalInstitutionLabelComponentType.Textarea:
      return {
        labelId: label.id.toString(),
        textCondition: {
          value: '',
        },
      };
    case MedicalInstitutionLabelComponentType.Integer:
      return {
        labelId: label.id.toString(),
        integerCondition: {
          conditionType: IntegerConditionType.Values.moreEqual,
        },
      };
    case MedicalInstitutionLabelComponentType.Select:
    case MedicalInstitutionLabelComponentType.Radio:
    case MedicalInstitutionLabelComponentType.Area:
      return {
        labelId: label.id.toString(),
        selectionCondition: {
          ids: [],
          conditionType: SelectionConditionType.Values.in,
        },
      };
    case MedicalInstitutionLabelComponentType.Checkbox:
      return {
        labelId: label.id.toString(),
        selectionCondition: {
          ids: [],
          conditionType: SelectionConditionType.Values.haveAny,
        },
      };
  }
};
