import { FacilityTypeFilterModal } from '@/src/components/features/UtilModal/FacilityTypeFilterModal';
import { Chip } from '@/src/components/foundations/Buttons/Chip';
import { useModal } from '@/src/hooks/useModal';

type Props = {
  onSubmit: React.ComponentProps<typeof FacilityTypeFilterModal>['onSubmit'];
  selectedFacilityTypeIds: string[];
  size: 'small' | 'medium';
};

export const FacilityTypeFilterChipPresenter: React.FC<Props> = (props) => {
  const facilityTypeFilterModal = useModal();

  return (
    <>
      <Chip
        size={props.size}
        status={props.selectedFacilityTypeIds.length > 0 ? 'active' : 'normal'}
        onClick={facilityTypeFilterModal.show}
      >
        {props.selectedFacilityTypeIds.length > 0
          ? `施設タイプ：${props.selectedFacilityTypeIds.length}`
          : '施設タイプ'}
      </Chip>
      {facilityTypeFilterModal.isShown && (
        <FacilityTypeFilterModal
          isShown={facilityTypeFilterModal.isShown}
          onClose={facilityTypeFilterModal.hide}
          selectedFacilityTypeIds={props.selectedFacilityTypeIds}
          onSubmit={(values) => {
            facilityTypeFilterModal.hide();
            props.onSubmit(values);
          }}
        />
      )}
    </>
  );
};
