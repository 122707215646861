import range from 'lodash.range';

const FIRST_PAGE = 1;

const PAGE_COUNT_TO_SHOW = 5;

const DISTANCE_FROM_START_TO_END = PAGE_COUNT_TO_SHOW - 1;

const DISTANCE_TO_CENTER = Math.floor(DISTANCE_FROM_START_TO_END / 2);

const convertStartPageAndEndPageToRangeArgs = (
  startPage: number,
  endPage: number,
): [number, number] => [startPage, endPage + 1];

export const getPagesToShow = ({
  currentPage,
  lastPage,
}: {
  currentPage: number;
  lastPage: number;
}): number[] => {
  const startPageAndEndPage: readonly [number, number] = (() => {
    if (lastPage < PAGE_COUNT_TO_SHOW) {
      const startPage = FIRST_PAGE;
      const endPage = lastPage;

      return [startPage, endPage] as const;
    }

    if (currentPage < FIRST_PAGE + DISTANCE_TO_CENTER) {
      const startPage = FIRST_PAGE;
      const endPage = startPage + DISTANCE_FROM_START_TO_END;

      return [startPage, endPage] as const;
    }

    if (lastPage - DISTANCE_TO_CENTER < currentPage) {
      const endPage = lastPage;
      const startPage = endPage - DISTANCE_FROM_START_TO_END;

      return [startPage, endPage] as const;
    }

    const startPage = currentPage - DISTANCE_TO_CENTER;
    const endPage = currentPage + DISTANCE_TO_CENTER;

    return [startPage, endPage] as const;
  })();

  const rangeArgs = convertStartPageAndEndPageToRangeArgs(
    ...startPageAndEndPage,
  );

  return range(...rangeArgs);
};
