import { GetMedicalInstitutionLabelsResponse } from '@/src/api/generated';
import { medicalInstitutionLabelsApi } from '@/src/api/index';
import { useFetchError } from '@/src/error/fetchError/hooks/useFetchError';
import { useAsyncFetch, AsyncFetchResponse } from '@/src/hooks/useAsyncFetch';
import { apiKey } from '@/src/hooks/useAsyncFetch/apiKey';

export const useGetMedicalInstitutionLabels =
  (): AsyncFetchResponse<GetMedicalInstitutionLabelsResponse> => {
    const throwFetchError = useFetchError();
    const labelsResponse = useAsyncFetch(
      apiKey('medicalInstitutionLabelsApi', 'getMedicalInstitutionLabels'),
      () => medicalInstitutionLabelsApi.getMedicalInstitutionLabels(),
      (error) => throwFetchError(error),
    );

    return labelsResponse;
  };
