import {
  MedicalInstitutionLabel,
  MedicalInstitutionLabelComponentType,
} from '@/src/api/generated';
import { ModalDialog } from '@/src/components/foundations/Utils/ModalDialog';
import { useGetMedicalInstitutionLabels } from '@/src/hooks/fetcher/useGetMedicalInstitutionLabels';
import { valueAsNumberOrNull } from '@/src/hooks/useFormWrapper';
import { MedicalInstitutionLabelFilterConditionSchema } from '@/src/types/medicalInstitutionLabel';

import { Area } from './Area';
import { Checkbox } from './Checkbox';
import { Integer } from './Integer';
import { SelectOrRadio } from './SelectOrRadio';
import { Text } from './Text';
import { useFiltersForm } from './form';
import { isConditionEnabled } from './mapper';
import styles from './styles.module.scss';

import difference from 'lodash.difference';

type Props = {
  isShown: boolean;
  labelFilterConditions: MedicalInstitutionLabelFilterConditionSchema[];
  onSubmit: (
    labelFilterConditions: MedicalInstitutionLabelFilterConditionSchema[],
  ) => void;
  onClose: VoidFunction;
};

export const MedicalInstitutionLabelFilterModal: React.FC<Props> = (props) => {
  const allLabelsResponse = useGetMedicalInstitutionLabels();
  if (!allLabelsResponse.data) return null;

  return (
    <MedicalInstitutionLabelFilterModalForm
      labels={allLabelsResponse.data.labels}
      {...props}
    />
  );
};

// RHFでのフォーム定義と、非同期でのデータ読み込みを分けるための内部コンポーネント
const MedicalInstitutionLabelFilterModalForm: React.FC<
  Props & { labels: MedicalInstitutionLabel[] }
> = (props) => {
  const { form } = useFiltersForm(props.labelFilterConditions, props.labels);

  return (
    <ModalDialog
      isShown={props.isShown}
      size="wide"
      title="医療機関ラベル"
      okButton={{
        children: '保存する',
        isDisabled: !form.formState.isValid,
        onClick: form.handleSubmit((data) => {
          props.onSubmit(data.conditions.filter(isConditionEnabled));
        }),
      }}
      cancelButton={{
        children: '閉じる',
        onClick: props.onClose,
        color: 'basic',
      }}
    >
      <form>
        {props.labels.map((label, i) => {
          switch (label.componentType) {
            case MedicalInstitutionLabelComponentType.Text:
            case MedicalInstitutionLabelComponentType.Textarea:
              return (
                <section key={label.id} className={styles.section}>
                  <Text
                    label={label}
                    form={{
                      value: form.register(
                        `conditions.${i}.textCondition.value`,
                      ),
                    }}
                  />
                </section>
              );
            case MedicalInstitutionLabelComponentType.Integer:
              return (
                <section key={label.id} className={styles.section}>
                  <Integer
                    label={label}
                    form={{
                      value: form.register(
                        `conditions.${i}.integerCondition.value`,
                        {
                          setValueAs: valueAsNumberOrNull,
                        },
                      ),
                      conditionType: form.register(
                        `conditions.${i}.integerCondition.conditionType`,
                      ),
                    }}
                  />
                </section>
              );
            case MedicalInstitutionLabelComponentType.Radio:
            case MedicalInstitutionLabelComponentType.Select: {
              const selectionIds =
                label.selections?.map((s) => s.id.toString()) ?? [];
              const isAllSelected =
                difference(
                  selectionIds,
                  form.watch(`conditions.${i}.selectionCondition.ids`),
                ).length === 0;

              return (
                <section key={label.id} className={styles.section}>
                  <SelectOrRadio
                    label={label}
                    form={{
                      value: form.register(
                        `conditions.${i}.selectionCondition.ids`,
                      ),
                      conditionType: form.register(
                        `conditions.${i}.selectionCondition.conditionType`,
                      ),
                    }}
                    isAllSelected={isAllSelected}
                    groupToggle={() =>
                      form.setValue(
                        `conditions.${i}.selectionCondition.ids`,
                        isAllSelected ? [] : selectionIds,
                      )
                    }
                  />
                </section>
              );
            }
            case MedicalInstitutionLabelComponentType.Checkbox: {
              const selectionIds =
                label.selections?.map((s) => s.id.toString()) ?? [];
              const isAllSelected =
                difference(
                  selectionIds,
                  form.watch(`conditions.${i}.selectionCondition.ids`),
                ).length === 0;

              return (
                <section key={label.id} className={styles.section}>
                  <Checkbox
                    label={label}
                    form={{
                      value: form.register(
                        `conditions.${i}.selectionCondition.ids`,
                      ),
                      conditionType: form.register(
                        `conditions.${i}.selectionCondition.conditionType`,
                      ),
                    }}
                    isAllSelected={isAllSelected}
                    groupToggle={() =>
                      form.setValue(
                        `conditions.${i}.selectionCondition.ids`,
                        isAllSelected ? [] : selectionIds,
                      )
                    }
                  />
                </section>
              );
            }
            case MedicalInstitutionLabelComponentType.Area: {
              const areaIds = label.areas?.map((s) => s.id.toString()) ?? [];
              const isAllSelected =
                difference(
                  areaIds,
                  form.watch(`conditions.${i}.selectionCondition.ids`),
                ).length === 0;

              return (
                <section key={label.id} className={styles.section}>
                  <Area
                    label={label}
                    form={{
                      value: form.register(
                        `conditions.${i}.selectionCondition.ids`,
                      ),
                      conditionType: form.register(
                        `conditions.${i}.selectionCondition.conditionType`,
                      ),
                    }}
                    isAllSelected={isAllSelected}
                    groupToggle={() =>
                      form.setValue(
                        `conditions.${i}.selectionCondition.ids`,
                        isAllSelected ? [] : areaIds,
                      )
                    }
                  />
                </section>
              );
            }
          }
        })}
      </form>
    </ModalDialog>
  );
};
